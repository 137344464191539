import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { GrHomeRounded } from 'react-icons/gr';
import '../../pages/organisation/OrganisationPage.scss';
import { useState, useContext } from 'react';
import SearchContext from '../../commons/store/search-context';
import { MenuItem, Select, Chip, OutlinedInput, Checkbox, ListItemText, InputLabel, FormControl } from '@mui/material';

const Top = (props) => {
  const searchContext = useContext(SearchContext);
  const [name, setName] = useState(searchContext.name);
  const [selectedOptions, setSelectedOptions] = useState([]); // Ensure this is an array

  const handleOnChange = (event) => {
    setName(event.target.value);
    props.onNameChange(event.target.value.trim());
  };    

  const handleOnSearch = (e) => {
    e.preventDefault();
    searchContext.handleOnSearch({
      type: searchContext.type,
      name: name.trim(),
      selectedOptions,
    });
    if (props.onSearch) {
      props.onSearch();
    }
  };

  const handleChipChange = (event) => {
    const {
      target: { value },
    } = event;
    const updatedOptions = typeof value === 'string' ? value.split(',') : value;
    setSelectedOptions(updatedOptions);

    console.log("updatedOptions", updatedOptions);

    if (props.onChipChange) {
      props.onChipChange(updatedOptions);
    }
  };

  const options = props.options || [];

  return (
    <div className="top d-flex justify-content-between align-items-center">
      <Breadcrumbs
        aria-label="breadcrumb"
        className="breadcrump"
        style={{ marginLeft: '10px', marginRight: '10px' }}
      >
        <Link to="/">
          <GrHomeRounded size={16} />
        </Link>
      </Breadcrumbs>
      <form
        className="input-group-org d-flex flex-row align-items-center"
        onSubmit={handleOnSearch}
      >
      { props.hasChip && (
        <FormControl sx={{ marginRight: '10px', minWidth: '150px' }}>
          <InputLabel id="demo-multiple-checkbox-label"> {props.chipTitle} </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label" // Associate label with Select
            id="demo-multiple-checkbox"
            multiple
            value={selectedOptions}
            onChange={handleChipChange}
            input={<OutlinedInput label={props.chipTitle} />} // Ensure label works properly with OutlinedInput
            renderValue={(selected) => selected.join(', ')} // Joins selected options with a comma
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8, // Limits menu height
                  width: 250, // Adjust menu width
                },
              },
            }}
            sx={{
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={selectedOptions.includes(option)} /> {/* Checkbox for each option */}
                <ListItemText primary={option} /> {/* Displays option text */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      
      )}
        <div className="input-icon-org d-flex flex-row justify-content-start align-items-center border flex-grow-1">
          <img className="icon-search-org" src="/images/icons/Vector.svg" />
          <input
            type="text"
            className="border-0 outline-none h4"
            placeholder="Enter search keywords here"
            value={name}
            onChange={handleOnChange}
          />
        </div>
        <button
          type="submit"
          className="btn input-group-text-org d-flex justify-content-center align-items-center"
        >
          Search
        </button>
      </form>
    </div>
  );
};
export default Top;
