import React from 'react';
import Top from '../../components/organisation/Top';
import Sidebar from '../../components/organisation/Sidebar';
import ExpertPageFilter from '../../components/expert/ExpertPageFilter';
import { Collapse, ThemeProvider } from '@mui/material';
import ExpertContent from '../../components/expert/ExpertContent';
import theme from '../../commons/store/filterThemeProvider';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const ExpertPage = () => {
  const [isOpenFilter, setIsOpenFilter] = React.useState(true);
  const [relatedTech, setRelatedTech] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const [hIndexes, setHIndexes] = React.useState([]);
  const [isApplyFilter, setApplyFilter] = React.useState(false);
  const [handleTriggerFilter, setTriggerFilter] = React.useState(false);
  const [name, setName] = React.useState('');
  const [state, setState] = React.useState(useLocation().state);
  const isSmaller = useMediaQuery({ maxWidth: 1040 });
  const [selectedChips, setSelectedChips] = React.useState([]);

  const countryOptions = ['Anh', 'Ba Lan', 'Belarus', 'Brazil', 'Bỉ', 'Bồ Đào Nha', 'Canada', 'Chile', 'Các Tiểu Vương quốc Ả Rập Thống nhất', 'Cộng Hòa Séc', 'Estonia', 'Hungary', 'Hà Lan', 'Hàn Quốc', 'Hồng Kông', 'Iceland', 'Ireland', 'Kazakhstan', 'Kuwait', 'Luxembourg', 'Malaysia', 'Mexico', 'Mỹ', 'Na Uy', 'New Zealand', 'Nga', 'Nhật Bản', 'Pakistan', 'Papua New Guinea', 'Pháp', 'Phần Lan', 'Saudi Arabia', 'Scotland', 'Singapore', 'Slovakia', 'Slovenia', 'Thái Lan', 'Thổ Nhĩ Kỳ', 'Thụy Sĩ', 'Thụy Điển', 'Trung Quốc', 'Tây Ban Nha', 'Việt Nam', 'Áo', 'Úc', 'Ý', 'Đan Mạch', 'Đài Loan', 'Đức', 'Ấn Độ'];

  const handleChipChange = (chips) => {
    setSelectedChips(chips);
  };

  React.useEffect(() => {
    if (state) {
      setHIndexes(state.hIndexes);
    }
  }, []);

  return (
    <>
      <Top 
        hasChip={true}
        onNameChange={setName} 
        onChipChange={handleChipChange}
        options={countryOptions}
        chipTitle="Quốc gia"
      />
      <div className="organisation-content">
        <Sidebar isOpen={isOpenFilter} onSelect={setIsOpenFilter} />

        {isSmaller ? (
          <ExpertPageFilter
            onTechChange={setRelatedTech}
            onHIndexesChange={setHIndexes}
            onFilterChange={setApplyFilter}
            onOrgChange={setOrg}
            hIndexes={hIndexes}
            onTriggerFilter={() => {
              setTriggerFilter(!handleTriggerFilter);
              setState(null);
            }}
            state={state}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <Collapse in={isOpenFilter} orientation="horizontal">
              <ExpertPageFilter
                onTechChange={setRelatedTech}
                onHIndexesChange={setHIndexes}
                onFilterChange={setApplyFilter}
                onOrgChange={setOrg}
                hIndexes={hIndexes}
                onTriggerFilter={() => {
                  setTriggerFilter(!handleTriggerFilter);
                  setState(null);
                }}
                state={state}
              />
            </Collapse>
          </ThemeProvider>
        )}

        <ExpertContent
          name={name}
          relatedTech={relatedTech}
          hIndexes={hIndexes}
          org={org}
          isApplyFilter={isApplyFilter}
          handleTriggerFilter={handleTriggerFilter}
          state={state}
          country={selectedChips}
        />
      </div>
    </>
  );
};

export default ExpertPage;
